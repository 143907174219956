<template>
  <v-layout column px-3>
    <v-layout row align-center class="upload-file__info">
      <w-icon small class="mr-2" :color="iconColor">{{ fileIcon }}</w-icon>
      <v-flex class="upload-item__name">
        <v-tooltip v-if="fullpath" bottom>
          <template v-slot:activator="{ on, attrs }">
            <span class="text-truncate" v-bind="attrs" v-on="on">{{ name }}</span>
          </template>
          <span>{{ fullpath }}</span>
        </v-tooltip>
        <span v-else class="text-truncate">{{ name }}</span>
        <div v-if="type !== 'folder'" class="upload-item__size">
          {{ $humanReadableSize(size) }}
        </div>
      </v-flex>
      <v-flex v-if="!showProgress" shrink class="upload-item__percentage ml-2">{{ progression }}%</v-flex>
      <v-flex v-if="showCheckmark" shrink>
        <w-icon small class="upload-item__check" color="success">fas fa-check</w-icon>
      </v-flex>
      <v-flex v-if="failed" shrink>
        <w-icon small class="upload-item__error" color="error">fas fa-exclamation-circle</w-icon>
        <w-btn color="error" flat icon="close" mini small @click="removeUpload" />
      </v-flex>
    </v-layout>
    <v-layout v-if="showProgress && !failed && progression < 100" align-center row>
      <v-flex>
        <v-progress-linear
          color="primary"
          height="4"
          rounded
          :value="progression"
        ></v-progress-linear>
      </v-flex>
      <v-flex shrink class="upload-progress-bar__percentage ml-2">{{ progression }}%</v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { getFileIconSettings } from '@/helpers/files'
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'

export default {
  name: 'UploadFileItem',
  mixins: [AppModuleGuard],
  props: {
    failed: {
      default: false,
      type: Boolean
    },
    fullpath: {
      default: null,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    progression: {
      default: 0,
      type: Number,
    },
    size: {
      required: true,
      type: Number
    },
    showCheckmark: {
      default: false,
      type: Boolean
    },
    showProgress: {
      default: false,
      type: Boolean
    },
    type: {
      default: '',
      type: String
    }
  },
  computed: {
    fileIcon: function () {
      let icon

      if (this.type === 'folder') {
        icon = 'fas fa-folder'
      } else {
        const iconSettings = getFileIconSettings(this.type)
        icon = iconSettings?.icon || 'fas fa-file'
      }
      
      return icon
    },
    iconColor: function () {
      let result = undefined

      if (this.failed) {
        result = 'error'
      } else if (this.type === 'folder') {
        result = 'primary'
      }

      return result
    }
  },
  methods: {
    removeUpload: function () {
      this.$emit('hide')
    }
  }
}
</script>

<style scoped>
.upload-file__info {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.upload-item__name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  font-size: 13px;
  line-height: 1.2;
  padding-right: 8px;
  min-width: 0;
}

.upload-item__name .text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  flex: 1;
}

.upload-item__size {
  font-size: 12px;
  white-space: nowrap;
  text-align: right;
  margin-left: 8px;
}

.upload-item__check {
  margin-left: 8px;
}

.upload-item__error {
  margin-left: 8px;
}

.upload-item__percentage {
  font-size: 12px;
  min-width: 40px;
  text-align: right;
}
</style> 